/* App config for apis
 */
import dotenv from "dotenv"

const apiConstants = {
  BASE_URL: process.env.REACT_APP_API_URL,
  // process.env.REACT_APP_API_URL,
  //'http://localhost:5500/api/',  //"https://vc-backend.onrender.com/api/", //'http://localhost:5500/api/' //'https://troms-api-dev.herokuapp.com/api/', //https://troms-api-dev.herokuapp.com //https://troms-api-test.herokuapp.com
 
 
  LOGIN: "user/login",
  CREATEUSER: "user/create",
  REGISTER: "user/register",
  EXTEND_TOKEN: "user/extendToken",
  UPDATE_USER: "user/update",
  FORGOT_PASSWORD: "user/forgot",
  CHANGE_PASSWORD: "user/changePassword",
  GET_UNAUTHORISED_LIST: "user/authoriseList",
  AUTHORISE_USER: "user/authorise",
  VERIFY_USER_2FA: "user/otp/verify",
  REQUEST_QR_CODE_WITH_AUTH: "user/otp/requestqr",
  SETUP_2FA: "user/otp/setup",
  DESTROY_TOKEN: "user/destroyToken",
  REQUEST_2FA_BACKUP: "user/otp/email",
  GET_CLINICIANS: "user/get-clinicians",
  
  CREATE_TEMPLATE: "templates/create",
  GET_TEMPLATES: "templates/get",

  CREATE_PATIENT: "patient/create",
  UPDATE_PATIENT: "patient/update",
  SEARCH_PATIENT: "patient/search",
  CHANGE_PATIENT_STATUS: "patient/update/status",
  GET_PATIENT: "patient/get/details",
  GET_RECORDS: "patient/get",
  IMPORT_PATIENTS: "patient/import",
  BULK_ADD_PATIENTS: "patient/bulk-add",
  GET_LISTED_PATIENTS: "patient/listed",
  
  CREATE_CONSENT: "consent/create",
  SEND_FOR_SIGNATURE: "consent/send",
  UPDATE_CONSENT: "/consent/update",
  GET_CONSENTS: "/consent/get/all",
  GET_CONSENTS_BY_PATIENT_ID: "consent/get/consents",
  DOWNLOAD_CONSENT: "consent/download",
  GET_S3_URL: "consent/get/private-url",
  SEND_CONSENT_EMAIL: "consent/sendEmail",
  SEND_CONSENT_TO_SIGN: "consent/sendConsentToSign",
  VERIFY_USER: "consent/verify-user",
  GET_DETAILS_BY_TOKEN: "consent/details-by-token",
  BITPAPER_UPLOAD: "consent/bitpaper-upload",
  GET_HOSPITALS: "hospitals",

  ORG_SEARCH_VAL: "config/search-organisation?q=",
  CONFIG: "config",

  SUBSCRIPTION: "subscription/active-list",

  FAQ_LIST: "faqs/list",

  RECORD_PDF: "record/pdf",
  UPDATE_RECORD: "record/update",
  SEND_RECORD: "record/send",
  VERIFY_PATIENT: "record/verifyPatient",
  VERIFY_INTERPRETER: "record/verifyInterpreter",
  SEND_INVITE: "record/sendInvite",
  REOPEN_RECORD: "record/reopen",
  GET_PDF_URL: "record/pdfGetter",
  FINAL_IMPORT: "/record/import",
  DELETE_OLD: "/record/delete",
  //
  IMPORT_CONSENTS: "/importConsent",

  CREATE_PROCEDURE: "/procedure/create",
  CHANGE_PROCEDURE_STATUS: "/procedure/update/status",
  UPDATE_PROCEDURE: "/procedure/update",
  UPDATE_PERTICULAR_PROCEDURE: "procedure/edit/resources",
  SEARCH_PROCEDURES: "/procedure/get",
  PROCEDURE_DETAILS: "/procedure/get/details",
  ADD_TO_SECTION: "/procedure/add/section",

  GET_ROLES: "/role/get",
  UPDATE_ROLE: "/role/update",
  CREATE_ROLE: "/role/create",
  DELETE_ROLE: "/role/delete",
}

export default apiConstants
