import { all, fork } from "redux-saga/effects"

//public
import AccountSaga from "./auth/register/saga"
import AuthSaga from "./auth/login/saga"
import ForgetSaga from "./auth/forgetpwd/saga"
import ProfileSaga from "./auth/profile/saga"
import LayoutSaga from "./layout/saga"
import dashboardSaga from "./dashboard/saga"
import dashboardSaasSaga from "./dashboard-saas/saga"
import faqSaga from "./faq/saga"
import userManagementSaga from "./userManagement/saga"
import mfaSaga from "./auth/mfa/saga"
import consentSaga from "./consent/saga"
import recordsSaga from "./records/saga"
import patientSaga from "./patient/saga"
import interpreterSaga from "./interpreter/saga"
import procedureSaga from "./proceuders/saga"
import templateSaga from "./template/saga"
import roleSaga from "./role/saga"

export default function* rootSaga() {
  yield all([
    //public
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ProfileSaga),
    fork(LayoutSaga),
    fork(dashboardSaga),
    fork(dashboardSaasSaga),
    fork(faqSaga),
    fork(userManagementSaga),
    fork(mfaSaga),
    fork(consentSaga),
    fork(recordsSaga),
    fork(patientSaga),
    fork(interpreterSaga),
    fork(procedureSaga),
    fork(templateSaga),
    fork(roleSaga)
  ])
  console.log("SAGAS loaded")
}
