import { createActions } from "reduxsauce"

export const { Types: patientTypes, Creators: patientCreators } = createActions(
  {
    setStep: ["step"],
    verifyPatientRequest: ["id", "patientDetails"],
    verifyPatientResponse: ["url"],
    verifyPatientFailure: ["error"],
    createProcuders: ["obj"],
    createPatient: ["payload"],
    setPatient: ["obj"],
    setFormToggle: ["payload"],
    setPatientId: ["id"],
    updatePatient: ["id", "patientDetails"],
    setLoadingAddPatient: ["payload"],
    changePatientStatus: ["payload"],
    getDashboardPatientList: ["payload"],
    setDashboardPatientList: ["payload"],
    importPatients: ['payload'],
    setImportedList: ['list'],
    bulkAddPatient: ['payload'],
    setBulkAddPatientLoading: ['bool'],
    setExportListSuccess: ['bool'],
    getListedPatients: ["payload"],
    setListedPatients: ["patients"],
    setPatientListLoading: ['bool']
  }
)

const InitialState = {
  step: 1,
  url: null,
  loading: null,
  error: null,
  patient: {},
  toggleForm: null,
  id: null,
  loadingAddPatient: false,
  dashboardPatientList: [],
  exportListSuccess: false,
  importedList: [],
  bulkAddPatientLoading: false,
  listedPatients: [],
  patientListLoading: false
}

const patientReducer = (state = InitialState, action) => {
  switch (action.type) {
    case patientTypes.SET_STEP:
      return {
        ...state,
        step: action.step,
      }
    case patientTypes.SET_PATIENT:
      return {
        ...state,
        patient: action.obj,
      }
    case patientTypes.SET_IMPORTED_LIST:
      return {
        ...state,
        importedList: action.list,
      }
    case patientTypes.SET_EXPORT_LIST_SUCCESS:
      return {
        ...state,
        exportListSuccess: action.bool,
      }
    case patientTypes.SET_BULK_ADD_PATIENT_LOADING:
      return {
        ...state,
        bulkAddPatientLoading: action.bool,
      }
    case patientTypes.SET_LISTED_PATIENTS:
      return {
        ...state,
        listedPatients: action.patients,
      }
    case patientTypes.SET_PATIENT_LIST_LOADING: 
      return {
        ...state,
        patientListLoading: action.bool
      }
    case patientTypes.GET_LISTED_PATIENTS:
    case patientTypes.BULK_ADD_PATIENT:
    case patientTypes.CHANGE_PATIENT_STATUS:
    case patientTypes.IMPORT_PATIENTS:
    case patientTypes.GET_DASHBOARD_PATIENT_LIST:
      return {
        ...state,
      }
    case patientTypes.SET_DASHBOARD_PATIENT_LIST:
      return {
        ...state,
        dashboardPatientList: action.payload
      }
    case patientTypes.VERIFY_PATIENT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case patientTypes.VERIFY_PATIENT_RESPONSE:
      return {
        ...state,
        loading: false,
        error: null,
        url: action.url,
      }
    case patientTypes.VERIFY_PATIENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        url: null,
      }
    case patientTypes.CREATE_PATIENT:
      return {
        ...state,
        loading: false,
        // error: action.error,
        // url: null,
      }
    case patientTypes.SET_FORM_TOGGLE:
      return {
        ...state,
        toggleForm: action.payload,
      }

    case patientTypes.SET_LOADING_ADD_PATIENT: {
      return {
        ...state,
        loadingAddPatient: action.payload,
      }
    }
    // case patientTypes.SET_PARENT_ID:
    //   return {
    //     ...state,
    //     id: action.payload,
    //   }

    default:
      return state
  }
}

export default patientReducer
