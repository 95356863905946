import { call, put, takeLatest } from "redux-saga/effects"
import { consentTypes, consentCreators } from "./reducer"
import {
  getTemplates,
  recordPDF,
  updateRecord,
  sendRecord,
  sendEmailInvite,
  sendReopenReq,
  getBread,
  getProcedures,
  createNewConsent,
  updateConsents,
  sendForSignature,
  getConsents,
  downloadConsent,
  sendConsentToSign,
  verifyNewUser,
  getDetailsByToken,
  uploadBitpaperUrl,
} from "servicies/UserServicies"
import { appCreators } from "store/app/appReducer"
import escapeHtml from "escape-html"
import { Text } from "slate"
import { showToast } from "utils/toastnotify"

function* fetchTemplates(action) {
  try {
    const response = yield call(getTemplates)
    if (response.ok) {
      yield put(consentCreators.successTemplates(response.data))
    } else {
      yield put(consentCreators.failureTemplates())
    }
  } catch (error) {
    yield put(consentCreators.failureTemplates())
  }
}
let order = 0 //Signing order
//This is where the object is converted into html. It uses recursion to loop through all the possible nodes
const serialize = node => {
  if (Text.isText(node)) {
    let string = escapeHtml(node.text)
    if (node.bold) {
      string = `<strong>${string}</strong>`
    }
    if (node.italic) {
      string = `<em>${string}</em>`
    }
    if (node.underline) {
      string = `<u>${string}</ul>`
    }
    return string
  }

  const children = node.children.map(n => serialize(n)).join("")
  switch (node.type) {
    case "paragraph":
      return `<p>${children}</p>`
    case "link":
      return `<a href="${escapeHtml(node.url)}">${children}</a>`
    case "heading-one":
      return `<h1>${children}</h1>`
    case "heading-two":
      return `<h2>${children}</h2>`
    case "list-item":
      return `<li>${children}</li>`
    case "bulleted-list":
      return `<ul>${children}</ul>`
    case "numbered-list":
      return `<ol>${children}</ol>`
    case "border":
      return `<p style="border: 1px solid black !important;">${children}</p>`
    case "consent-patient":
      debugger
      order++
      return `
            <h4>Patient Consent</h4>
            <p><em><strong>Signature: </strong></em><em>{{Signature:Recipient${order}*}} </em></p>
        <p><em><strong>Date: </strong></em><em>{{Customdate:Recipient${order}*}}</em></p>
        <p><em><strong>Name: </strong></em><em>{{Fullname:Recipient${order}*}} </em></p>
        <p><em><strong>Contacts: </strong></em><em>{{Textfield:Recipient${order}*:Contact details}} </em></p>
        `
    case "consent-clinic":
      debugger
      order++
      return `
            <h4>Clinician Consent</h4>
            <p><em><strong>Signature: </strong></em><em>{{Signature:Recipient${order}*}} </em></p>
        <p><em><strong>Date: </strong></em><em>{{Customdate:Recipient${order}*}}</em></p>
        <p><em><strong>Name: </strong></em><em>{{Fullname:Recipient${order}*}} </em></p>
        <p><em><strong>Job:</strong></em><em> {{Jobtitle:Recipient${order}*}}</em></p>
        `
    case "consent-interpreter":
      order++
      return `
            <h4>Intepreter Consent</h4>
            <p><em><strong>Signature: </strong></em><em>{{Signature:Recipient${order}*}} </em></p>
        <p><em><strong>Date: </strong></em><em>{{Customdate:Recipient${order}*}}</em></p>
        <p><em><strong>Name: </strong></em><em>{{Fullname:Recipient${order}*}} </em></p>
        <p><em><strong>Job:</strong></em><em> {{Jobtitle:Recipient${order}*}}</em></p>
        `
    default:
      return children
  }
}

// uses the order variable to generate a proper order for zoho sign.
// gets reset when first called in uploadConsentRecord
const generateRecipients = (children, user) => {
  debugger
  let actions = []
  children.map((item, index) => {
    if (item.type == "consent-clinic") {
      actions.push({
        recipient_name: `${user.clinician.firstName} ${user.clinician.lastName}`,
        recipient_email: user.clinician.email,
        action_type: "SIGN",
        signing_order: actions.length,
      })
      console.log("Clinician recipient generated")
    }
    if (item.type == "consent-patient") {
      actions.push({
        recipient_name: `${user.patient.firstName} ${user.patient.lastName}`,
        recipient_email: user.patient.emailAddress,
        action_type: "SIGN",
        signing_order: actions.length,
      })
      console.log("patient recipient generated")
    }
    if (item.type == "consent-interpreter") {
      if (user.interpreter?.emailAddress) {
        try {
          actions.push({
            recipient_name: `${user.interpreter.name}`,
            recipient_email: user.interpreter.emailAddress,
            action_type: "SIGN",
            signing_order: actions.length,
          })
          console.log("intepreter recipient generated")
        } catch {
          console.log("interpreter consent failed")
        }
      }
    }
  })
  console.log(actions)
  return actions
}

export function* serializeData(action) {
  debugger
  try {
    order = 0
    const html = yield call(serialize, action.node)
    let children = action.node.children
    yield call(generateRecipients, children)
    yield put(consentCreators.serializeSlateObjectSuccess(html))
  } catch (error) {
    console.log("slate", error)
    yield put(consentCreators.serializeSlateObjectFailure())
  }
}

export function* uploadConsentRecord(action) {
  try {
    order = 0
    const html = yield call(serialize, action.node)
    let children = action.node.children
    let recipients = { patient: action.patient, clinician: action.clinician }
    if (action.interpreter?.name != undefined) {
      recipients = {
        patient: action.patient,
        clinician: action.clinician,
        interpreter: action.interpreter,
      }
    }
    const actions = yield call(generateRecipients, children, {
      patient: action.patient,
      clinician: action.clinician,
      interpreter: action.interpreter,
    })
    debugger
    const response = yield call(recordPDF, action.caseID, btoa(html), actions)
    if (response.ok) {
      const file = new Blob([response.data], { type: "application/pdf" }) //rewrite (add api for fetch presigned URL). pdfUrl needs to be set like patient and interpreter each time a record is loaded.
      const fileURL = URL.createObjectURL(file)
      yield put(consentCreators.uploadConsentRecordSuccess(fileURL))
      showToast("Consent form upload success", "success", false)
    } else {
      yield put(consentCreators.uploadConsentRecordFailure())
    }
  } catch (error) {
    console.log("slate", error)
    yield put(consentCreators.uploadConsentRecordFailure())
  }
  console.log("did the upload consent thing", action)
}

export function* updateConsentRecord(action) {
  try {
    const response = yield call(updateRecord, action.payload)
    if (response.ok) {
      if (response.data.message) {
        showToast(response.data.message, "success")
      }

      yield put(consentCreators.updateRecordSuccess())
    } else {
      showToast(response?.data?.message || "Something went wrong", "error")
      yield put(consentCreators.updateRecordFailure())
    }
  } catch (error) {
    console.log(error)
    showToast("Something went wrong", "error")
    yield put(consentCreators.updateRecordFailure())
  }
}

export function* sendConsentRecord(action) {
  try {
    debugger
    const response = yield call(sendRecord, action.payload)
    if (response.ok) {
      if (response.data.message) {
        showToast(response.data.message, "success")
      }

      yield put(consentCreators.sendRecordSuccess())
    } else {
      showToast(response?.data?.message || "Something went wrong", "error")
      yield put(consentCreators.sendRecordFailure())
    }
  } catch (error) {
    console.log(error)
    showToast("Something went wrong", "error")
    yield put(consentCreators.sendRecordFailure())
  }
}

export function* sendEmail(action) {
  try {
    const response = yield call(sendEmailInvite, {
      id: action.id,
      invites: action.invites,
    })
    if (response.ok) {
      showToast(response.data.message, "success")
      yield put(consentCreators.sendInviteResponse())
    } else {
      if (response.data.message) {
        showToast(response.data.message, "error")
        yield put(consentCreators.sendInviteResponse())
      } else {
        showToast("Something went wrong", "error")
        yield put(consentCreators.sendInviteResponse())
      }
    }
  } catch (error) {
    if (error?.data?.message) {
      showToast(error.data.message, "error")
      yield put(consentCreators.sendInviteResponse())
    } else {
      showToast("Something went wrong", "error")
      yield put(consentCreators.sendInviteResponse())
    }
  }
}

export function* reopenCase(payload) {
  try {
    const response = yield call(sendReopenReq, payload)
    if (response.ok) {
      showToast(response.data.message, "success")
      yield put(consentCreators.sendReopenResponse())
    } else {
      showToast(response?.data?.message || "Something went wrong", "error")
      yield put(consentCreators.sendReopenResponse())
    }
  } catch (error) {
    if (error?.data?.message) {
      showToast(error.data.message, "error")
      yield put(consentCreators.sendReopenResponse())
    } else {
      showToast("Something went wrong", "error")
      yield put(consentCreators.sendReopenResponse())
    }
  }
}

export function* pdfURL(payload) {
  try {
    const response = yield call(getBread, payload)
    if (response.ok) {
      showToast(response.data.message, "success")
      yield put(consentCreators.getBreadResponse())
    } else {
      showToast(response?.data?.message || "Something went wrong", "error")
      yield put(consentCreators.getBreadResponse())
    }
  } catch (error) {
    if (error?.data?.message) {
      showToast(error.data.message, "error")
      yield put(consentCreators.getBreadResponse())
    } else {
      showToast("Something went wrong", "error")
      yield put(consentCreators.getBreadResponse())
    }
  }
}

export function* searchProcedures(payload) {
  try {
    yield put(consentCreators.searchProceduresLoading(true))
    const response = yield call(
      getProcedures,
      payload?.search,
      payload?.page,
      payload?.limit,
      payload?.status
    )
    if (response.ok) {
      yield put(consentCreators.searchProceduresResponse(response))
    } else {
      console.log(response)
    }
    yield put(consentCreators.searchProceduresLoading(false))
  } catch (error) {
    yield put(consentCreators.searchProceduresLoading(false))
    if (error?.data?.message) {
      showToast(error.data.message, "error")
      yield put(consentCreators.searchProceduresResponse())
    } else {
      showToast("Something went wrong", "error")
      yield put(consentCreators.searchProceduresResponse())
    }
  }
}

export function* createConsent(payload) {
  try {
    yield put(consentCreators.setLoadingConsult(true))
    const response = yield call(createNewConsent, payload.obj)
    if (response.ok) {
      showToast(response.data.message, "success")
      yield put(consentCreators.setLoadingConsult(false))
      if(response?.data?.Timeline) {
        yield put(
          consentCreators.setTimeline(response?.data?.Timeline)
        )
      }
      if (payload?.isReconsented) {
        if (response?.data?.bitPaperPDFURL) {
          yield put(
            consentCreators.setBitpaperPdfURL(response?.data?.bitPaperPDFURL)
          )
        }
      }
      if (payload?.exit == true) {
        payload.history.push("/")
      } else {
        yield put(consentCreators.setActiveTab(payload.activeTab + 1))
      }
      yield put(consentCreators.setId(response.data.consentID))
      yield put(consentCreators.setBitpaperURL(response.data.bitpaper))
    } else {
      yield put(consentCreators.setLoadingConsult(false))
      console.log(response)
    }
  } catch (error) {
    yield put(consentCreators.setLoadingConsult(false))
    if (error?.data?.message) {
      showToast(error.data.message, "error")
    } else {
      showToast("Something went wrong", "error")
    }
  }
}

export function* updateConsent(payload) {
  debugger
  try {
    if (!payload?.isReconsented) {
      yield put(consentCreators.setLoadingConsult(true))
    }
    console.log(payload, "payload")
    const response = yield call(updateConsents, payload.payload)
    if (response.ok) {
      debugger
      console.log(response.data.bitPaperPDFURL, "RESPONSEN")
      if(response?.data?.Timeline) {
        yield put(
          consentCreators.setTimeline(response?.data?.Timeline)
        )
      }
      if (response?.data?.bitPaperPDFURL) {
        yield put(
          consentCreators.setBitpaperPdfURL(response?.data?.bitPaperPDFURL)
        )
      }
      if (response?.data?.preSignedDocumentUrl) {
        yield put(
          consentCreators.setPresignedDocumentUrl(
            response?.data?.preSignedDocumentUrl
          )
        )
      }
      if (!payload?.isReconsented) {
        yield put(consentCreators.setLoadingConsult(false))
        if (payload?.payload?.closeConsent) {
          // showToast("Consent has been closed", "success")
        } else {
          showToast(response.data.message, "success")
        }

        if (payload?.exit == true) {
          payload.history.push("/")
        } else if(payload?.submitClinicianSignature == undefined) {
          yield put(consentCreators.setActiveTab(payload.activeTab + 1))
        }
      }
    } else {
      if (!payload?.isReconsented) {
        yield put(consentCreators.setLoadingConsult(false))
      }
      // console.log(response)
      console.log(response)
    }
  } catch (error) {
    if (!payload?.isReconsented) {
      yield put(consentCreators.setLoadingConsult(false))
    }
    if (error?.data?.message) {
      showToast(error.data.message, "error")
    } else {
      showToast("Something went wrong", "error")
    }
  }
}

export function* uploadBitPaperUrlSaga(payload) {
  debugger
  try {
    const response = yield call(uploadBitpaperUrl, payload.payload)
    if (response.ok) {
      debugger
      if (response?.data?.bitPaperPDFURL) {
        yield put(
          consentCreators.setBitpaperPdfURL(response?.data?.bitPaperPDFURL)
        )
      }
    }
  } catch (error) {
    if (!payload?.isReconsented) {
      yield put(consentCreators.setLoadingConsult(false))
    }
    if (error?.data?.message) {
      showToast(error.data.message, "error")
    } else {
      showToast("Something went wrong", "error")
    }
  }
}

export function* sendPdfForSignature(payload) {
  debugger
  try {
    console.log(payload, "payload")
    const response = yield call(sendForSignature, payload.payload)
    if (response.ok) {
      debugger
      console.log(response.data.bitPaperPDFURL, "RESPONSEN")
      if (response?.data?.bitPaperPDFURL) {
        yield put(
          consentCreators.setBitpaperPdfURL(response?.data?.bitPaperPDFURL)
        )
      }
      showToast(response.data.message, "success")
      yield put(consentCreators.setActiveTab(payload.activeTab + 1))
    } else {
      // console.log(response)
    }
  } catch (error) {
    if (error?.data?.message) {
      showToast(error.data.message, "error")
    } else {
      showToast("Something went wrong", "error")
    }
  }
}

export function* getConsentsList(payload) {
  try {
    yield put(consentCreators.setLoadingDashboardConsents(true))
    const response = yield call(getConsents, payload)
    if (response.ok) {
      yield put(consentCreators.setLoadingDashboardConsents(false))
      yield put(consentCreators.setConsentResponse(response.data))
    } else if (
      response.error == "Expired or invalid token - please log in again"
    ) {
      showToast(response.error, "error")
      yield put(appCreators.clearToken())
    }
  } catch (err) {
    yield put(consentCreators.setLoadingDashboardConsents(false))
    console.log(err)
  }
}

export function* downloadConsentSaga(payload) {
  try {
    // const res1 = await axios.get()
    const response = yield call(downloadConsent, payload)
    if (response.ok) {
      const file = new Blob([response.data], { type: "application/pdf" })
      const fileURL = URL.createObjectURL(file)
      const link = document.createElement("a")
      link.href = fileURL
      link.setAttribute("download", `${payload.payload}_consent.pdf`)
      document.body.appendChild(link)
      link.click()
      URL.revokeObjectURL(fileURL)
      document.body.removeChild(link)
    } else if (
      response.error == "Expired or invalid token - please log in again"
    ) {
      showToast(response.error, "error")
      yield put(appCreators.clearToken())
    }
  } catch (err) {
    console.log(err)
  }
}

export function* sendConsentToSignSaga(payload) {
  try {
    yield put(consentCreators.setSendConsentToSignLoading(true))
    const response = yield call(sendConsentToSign, payload)
    if (response.ok) {
      if(response?.data?.Timeline) {
        yield put(
          consentCreators.setTimeline(response?.data?.Timeline)
        )
      }
      showToast(response?.data.message || "Email sent", "success")
    } else if (
      response.error == "Expired or invalid token - please log in again"
    ) {
      showToast(response.error, "error")
      yield put(appCreators.clearToken())
    }
    yield put(consentCreators.setSendConsentToSignLoading(false))
  } catch (err) {
    yield put(consentCreators.setSendConsentToSignLoading(false))
    console.log(err)
  }
}

export function* verifyUserSaga(action) {
  debugger
  try {
    yield put(consentCreators.setVerifyUserLoading(true))
    let history = action.payload.history;
    delete action.payload.history
    const response = yield call(verifyNewUser, action.payload)
    if (response?.status == 200) {
      showToast(response?.data?.message, "success")
      yield put(consentCreators.setIsUserVerified(true))
      history.push(`/consent/${action.payload.token}`)
    } else {
      yield put(consentCreators.setIsUserVerified(false))
      showToast(response?.data?.message || "Something went wrong", "error")
    }
    yield put(consentCreators.setVerifyUserLoading(false))
  } catch (error) {
    console.log(error)
    yield put(consentCreators.setVerifyUserLoading(false))
    showToast("Something went wrong", "error")
  }
}

export function* getDetailsByTokenSaga(action) {
  debugger
  try {
    yield put(consentCreators.setSignConsentDetailsLoading(true))
    const response = yield call(getDetailsByToken, action.payload)
    if (response?.status == 200) {
      yield put(consentCreators.setSignConsentDetailsResponse(response?.data?.data))
    } else {
      showToast(response?.data?.message || "Something went wrong", "error")
    }
    yield put(consentCreators.setSignConsentDetailsLoading(false))
  } catch (error) {
    console.log(error)
    yield put(consentCreators.setSignConsentDetailsLoading(false))
    showToast("Something went wrong", "error")
  }
}

function* consentSaga() {
  yield takeLatest(consentTypes.REQUEST_TEMPLATES, fetchTemplates)
  yield takeLatest(consentTypes.SERIALIZE_SLATE_OBJECT, serializeData)
  yield takeLatest(consentTypes.UPLOAD_CONSENT_RECORD, uploadConsentRecord)
  yield takeLatest(consentTypes.UPDATE_RECORD, updateConsentRecord)
  yield takeLatest(consentTypes.SEND_RECORD, sendConsentRecord)
  yield takeLatest(consentTypes.SEND_INVITE, sendEmail)
  yield takeLatest(consentTypes.REOPEN_CASE, reopenCase)
  yield takeLatest(consentTypes.GET_BREAD, pdfURL)
  yield takeLatest(consentTypes.SEARCH_PROCEDURES, searchProcedures)
  yield takeLatest(consentTypes.CREATE_CONSENT, createConsent)
  yield takeLatest(consentTypes.UPDATE_CONSENT, updateConsent)
  yield takeLatest(consentTypes.SEND_FOR_SIGNATURE, sendPdfForSignature)
  yield takeLatest(consentTypes.GET_CONSENTS, getConsentsList)
  yield takeLatest(consentTypes.DOWNLOAD_CONSENT, downloadConsentSaga)
  yield takeLatest(consentTypes.SEND_CONSENT_TO_SIGN, sendConsentToSignSaga)
  yield takeLatest(consentTypes.VERIFY_USER, verifyUserSaga)
  yield takeLatest(consentTypes.GET_DETAILS_BY_TOKEN, getDetailsByTokenSaga)
  yield takeLatest(consentTypes.UPLOAD_BITPAPER_URL, uploadBitPaperUrlSaga)
}

export default consentSaga
