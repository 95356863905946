import { call, put, takeLatest } from "redux-saga/effects"
import { recordTypes, recordCreators } from "./reducer"
import {
  recordList,
  importConsent,
  finalImport,
  deleteOldRecord,
} from "servicies/UserServicies"
import { appCreators } from "store/app/appReducer"
import { showToast } from "utils/toastnotify"

export function* getRecordData(action) {
  try {
    const response = yield call(
      recordList,
      action.page,
      action.limit,
      action.search,
      action?.status
    )
    if (response.ok) {
      yield put(recordCreators.successFetchRecords(response.data))
      console.log(response.data, "RECORD")
    } else if (
      response.error == "Expired or invalid token - please log in again"
    ) {
      showToast(response.error, "error")
      yield put(appCreators.clearToken())
    }
  } catch (err) {
    console.log(err)
  }
}

export function* importNewConsent(action) {
  const response = yield call(importConsent, action)
  console.log(response)
  try {
    if (response.ok) {
      yield put(recordCreators.successImportConsents(response.data))
      let msg =
        "Successfully parsed " +
        response.data.data.recordData.length +
        " records"
      showToast(msg, "success")
    } else {
      yield put(recordCreators.failureImportConsents())
      showToast(response.error, "error")
    }
  } catch (error) {
    console.log(error)
  }
}

export function* finalImportConsent(action) {
  const response = yield call(finalImport, action)
  try {
    if (response.ok) {
      yield put(recordCreators.successFinalImports())
    }
  } catch (error) {
    console.log(error)
  }
}

export function* deleteOldConsent(action) {
  const response = yield call(deleteOldRecord, action)

  try {
    if (response.ok) {
      yield put(recordCreators.successDeletion())
    }
  } catch (error) {
    console.log(error)
    showToast("Something went wrong", "error")
  }
}

function* recordsSaga() {
  yield takeLatest(recordTypes.REQUEST_FETCH_RECORDS, getRecordData),
    yield takeLatest(recordTypes.IMPORT_CONSENTS, importNewConsent),
    yield takeLatest(recordTypes.FINAL_IMPORT_CONSENTS, finalImportConsent),
    yield takeLatest(recordTypes.DELETE_OLD_CONSENT, deleteOldConsent)
}

export default recordsSaga
