import { useState, useEffect } from "react"

function useNetworkStatus() {
  const [isOnline, setIsOnline] = useState(navigator.onLine)
  const [isSlowConnection, setIsSlowConnection] = useState(false)

  const checkNetworkSpeed = () => {
    if (navigator?.connection) {
      const { effectiveType } = navigator?.connection
      setIsSlowConnection(effectiveType === "slow-2g" || effectiveType === "2g")
    }
  }

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true)
    }

    const handleOffline = () => {
      setIsOnline(false)
    }

    checkNetworkSpeed()

    window.addEventListener("online", handleOnline)
    window.addEventListener("offline", handleOffline)
    document.addEventListener(
      "deviceready",
      function () {
        navigator.connection.addEventListener("change", checkNetworkSpeed)
      },
      false
    )

    return () => {
      window.removeEventListener("online", handleOnline)
      window.removeEventListener("offline", handleOffline)
      navigator?.connection.removeEventListener("change", checkNetworkSpeed)
      document.removeEventListener("deviceready", function () {}, false)
    }
  }, [])

  return { isOnline, isSlowConnection }
}

export default useNetworkStatus
