import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

//App Reducer

import appReducer from "./app/appReducer"

// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import Profile from "./auth/profile/reducer"

//Dashboard
import Dashboard from "./dashboard/reducer"

//Dasboard saas
import DashboardSaas from "./dashboard-saas/reducer"

import consentReducer from "./consent/reducer"

import FaqlistReducer from "./faq/reducer"

import recordReducer from "./records/reducer"

import procedureReducer from "./proceuders/reducer"

import UserManagementReducer from "./userManagement/reducer"
import mfaReducer from "./auth/mfa/reducer"
import patientReducer from "./patient/reducer"
import interpreterReducer from "./interpreter/reducer"
import templateReducer from "./template/reducer"
import roleReducer from "./role/reducer"
import { createActions } from "reduxsauce"

export const { Types: mainReducerTypes, Creators: mainReducerCreators } =
  createActions({
    userLogout: [],
  })

const appMainReducer = combineReducers({
  // public
  Layout,
  appReducer,
  Login,
  Account,
  ForgetPassword,
  Profile,
  Dashboard,
  DashboardSaas,
  FaqlistReducer,
  UserManagementReducer,
  mfaReducer,
  consentReducer,
  recordReducer,
  patientReducer,
  interpreterReducer,
  procedureReducer,
  templateReducer,
  roleReducer
})

const rootReducer = (state, action) => {
  if (action.type === "USER_LOGOUT") {
    return appMainReducer(undefined, action)
  }
  return appMainReducer(state, action)
}

export default rootReducer
