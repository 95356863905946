import { createActions } from "reduxsauce"

export const { Types: templateTypes, Creators: templateCreators } =
  createActions({
    fetchTemplatesRequest: ["page", "limit", "search"],
    fetchTemplatesSuccess: ["templates"],
    fetchTemplatesFailuer: ["message"],
    createTemplateRequest: ["obj"],
    createTemplateSuccess: ["message"],
    createTemplateFailuer: ["message"],
  })

const InitialState = {
  template: {},
  error: null,
  loading: false,
  templates: [],
}

const templateReducer = (state = InitialState, action) => {
  console.log(action)
  switch (action.type) {
    case templateTypes.CREATE_TEMPLATE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case templateTypes.CREATE_TEMPLATE_SUCCESS:
      return {
        ...state,
        template: action.obj,
        error: null,
      }
    case templateTypes.CREATE_TEMPLATE_FAILURE:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case templateTypes.FETCH_TEMPLATES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case templateTypes.FETCH_TEMPLATES_SUCCESS:
      return {
        ...state,
        templates: action.templates,
        loading: false,
        error: null,
      }
    case templateTypes.FETCH_TEMPLATES_FAILUER:
      return {
        ...state,
        error: action.message,
      }
    default:
      return state
  }
}

export default templateReducer
