import { createActions } from "reduxsauce"

export const { Types: roleTypes, Creators: roleCreators } = createActions({
  requestRolesList: ["startDate","endDate","role", "updatedStartDate", "updatedEndDate"],
  successFetchRoles: ["data"],
  createRole: ["payload"],
  updateRole: ["payload"],
  setLoading: ["payload"],
  deleteRole: ["id"],
})

const InitialState = {
  roles: [],
  loading: false,
}

const roleReducer = (state = InitialState, action) => {
  switch (action.type) {
    case roleTypes.REQUEST_ROLES_LIST:
      return {
        ...state,
      }
    case roleTypes.SUCCESS_FETCH_ROLES:
      return {
        ...state,
        roles: action.data,
      }
    case roleTypes.CREATE_ROLE:
      return {
        ...state,
        loading: true,
      }
    case roleTypes.UPDATE_ROLE:
      return {
        ...state,
        loading: true,
      }
    case roleTypes.DELETE_ROLE:
      return {
        ...state,
      }
    case roleTypes.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      }

    default:
      return state
  }
}

export default roleReducer
