import { call, put, takeLatest } from "redux-saga/effects"
import { patientCreators, patientTypes } from "./reducer"
import { recordCreators } from "../records/reducer"
import {
  verifyUser,
  createNewPatient,
  updatePatient,
  updatePatientStatus,
  searchPatientFun,
  importPatientsFun,
  bulkAddPatientFun,
  getListedPatientsFun,
} from "servicies/UserServicies"
import { appCreators } from "store/app/appReducer"
import { showToast } from "utils/toastnotify"
import { consentCreators } from "store/consent/reducer"

export function* verifyPatient({ id, patientDetails }) {
  try {
    const response = yield call(verifyUser, { id, patientDetails })
    if (response.ok) {
      if (response.data.message) {
        showToast(response.data.message, "success")
      }
      yield put(patientCreators.verifyPatientResponse(response.data.url))
      yield put(patientCreators.setStep(2))
    } else {
      showToast(response?.data?.message || "Something went wrong", "error")
      yield put(patientCreators.verifyPatientResponse(response.data.message))
    }
  } catch (error) {
    // if (response.data.message) {
    //   showToast(response.data.message, "error")
    // } else {
    //   showToast("Something went wrong", "error")
    // }
    // yield put(patientCreators.verifyPatientResponse(response.data.message))
  }
}
export function* createPatient(action) {
  const history = action.payload.history;
  delete action.payload.history
  try {
    yield put(patientCreators.setLoadingAddPatient(true))
    const response = yield call(createNewPatient, action)
    if (response.ok) {
      showToast(response.data.message, "success")
      yield put(patientCreators.setFormToggle(false))
      yield put(patientCreators.setLoadingAddPatient(false))
      if(window.location.pathname == "/") {
        yield put(consentCreators.setVisibilityType("edit"))
        yield put(consentCreators.setConsentDetails({}))
        yield put(consentCreators.setActiveTab(1))
        yield put(consentCreators.setId(""))
        yield put(consentCreators.resetConsent(true))
        yield put(consentCreators.setPatient({...action.payload, id: response?.data?.recordID}))
        history.push("/create-consent")
      }else {
        yield put(recordCreators.requestFetchRecords(1, 10, null))
      }
      // yield put(patientCreators.setPatientId(response?.data?.recordID))
    } else if (
      response.error == "Expired or invalid token - please log in again"
    ) {
      yield put(patientCreators.setLoadingAddPatient(false))
      showToast(response.error, "error")
      yield put(appCreators.clearToken())
    } else {
      yield put(patientCreators.setLoadingAddPatient(false))
      showToast(response?.data?.message || "Something went wrong", "error")
      // yield put(fetchProcedureFailure(response.data))
    }
  } catch (error) {
    yield put(patientCreators.setLoadingAddPatient(false))
    // showToast(response.data.message, "error")
    console.log("error",error)
  }
}
export function* updatPatient(payload) {
  try {
    yield put(patientCreators.setLoadingAddPatient(true))
    const response = yield call(updatePatient, payload)
    if (response.ok) {
      yield put(patientCreators.setLoadingAddPatient(false))
      if(window?.location?.pathname != "/") {
        yield put(recordCreators.requestFetchRecords(1, 10, null))
      } else {
        yield put(patientCreators.getDashboardPatientList(""))
      }
      showToast(response.data.message, "success")
      yield put(patientCreators.setFormToggle(false))
    }
  } catch (error) {
    yield put(patientCreators.setLoadingAddPatient(false))
    // showToast(response.data.message, "error")
  }
}
export function* updatPatientStatus(action) {
  debugger
  try {
    let a = JSON.parse(action.payload)
    const response = yield call(updatePatientStatus, action.payload)
    if (response.ok) {
      if (response.data.message) {
        showToast(response.data.message, "success")
        if(window?.location?.pathname != "/") {
          yield put(recordCreators.requestFetchRecords(a?.page, 10, null))
        } else {
          yield put(patientCreators.getDashboardPatientList(""))
        }
      }
    } else {
      showToast(response?.data?.message || "Something went wrong", "error")
    }
  } catch (error) {
    console.log(error)
    showToast("Something went wrong", "error")
  }
}

export function* getDashboardPatientList(action) {
  debugger
  try {
    const response = yield call(searchPatientFun, action.payload)
    if (response?.status == 200) {
      yield put(patientCreators.setDashboardPatientList(response?.data?.data))
    } else {
      showToast(response?.data?.message || "Something went wrong", "error")
    }
  } catch (error) {
    console.log(error)
    showToast("Something went wrong", "error")
  }
}

export function* importPatients(action) {
  debugger
  try {
    const response = yield call(importPatientsFun, action.payload)
    if (response?.status == 200) {
      yield put(patientCreators.setImportedList(response?.data?.data))
      yield put(patientCreators.setExportListSuccess(true))
      // showToast("successfully imported","success")
    } else {
      showToast(response?.data?.message || "Something went wrong", "error")
    }
  } catch (error) {
    console.log(error)
    showToast("Something went wrong", "error")
  }
}

export function* addBulkPatients(action) {
  debugger
  try {
    yield put(patientCreators.setBulkAddPatientLoading(true))
    const response = yield call(bulkAddPatientFun, action.payload)
    if (response?.status == 200) {
      yield put(patientCreators.setBulkAddPatientLoading(false))
      showToast("successfully imported","success")
    } else {
      yield put(patientCreators.setBulkAddPatientLoading(false))
      showToast(response?.data?.message || "Something went wrong", "error")
    }
  } catch (error) {
    yield put(patientCreators.setBulkAddPatientLoading(false))
    console.log(error)
    showToast("Something went wrong", "error")
  }
}

export function* getListedPatients(action) {
  debugger
  try {
    yield put(patientCreators.setPatientListLoading(true))
    const response = yield call(getListedPatientsFun, action.payload)
    if (response?.status == 200) {
      yield put(patientCreators.setListedPatients(response?.data?.data))
    } else {
      showToast(response?.data?.message || "Something went wrong", "error")
    }
    yield put(patientCreators.setPatientListLoading(false))
  } catch (error) {
    console.log(error)
    yield put(patientCreators.setPatientListLoading(false))
    showToast("Something went wrong", "error")
  }
}

function* patientSaga() {
  yield takeLatest(patientTypes.VERIFY_PATIENT_REQUEST, verifyPatient)
  yield takeLatest(patientTypes.CREATE_PATIENT, createPatient)
  yield takeLatest(patientTypes.UPDATE_PATIENT, updatPatient)
  yield takeLatest(patientTypes.CHANGE_PATIENT_STATUS, updatPatientStatus)
  yield takeLatest(patientTypes.GET_DASHBOARD_PATIENT_LIST, getDashboardPatientList)
  yield takeLatest(patientTypes.IMPORT_PATIENTS, importPatients)
  yield takeLatest(patientTypes.BULK_ADD_PATIENT, addBulkPatients)
  yield takeLatest(patientTypes.GET_LISTED_PATIENTS, getListedPatients)
}

export default patientSaga
