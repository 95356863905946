import {call, put, takeLatest} from 'redux-saga/effects';
import {userManageTypes, userManageCreators} from "./reducer";
import {userList,userApprove, getCliniciansFunction} from "servicies/UserServicies";
import {appCreators} from "store/app/appReducer"
import { showToast } from 'utils/toastnotify';

export function* getUserData(action){
    try{
        //debugger;
        
        const response = yield call(userList,action.page,action.limit,action.status,action.search,action.role)
        if(response.ok){
            yield put(userManageCreators.successFetchUsers(response.data))
        }
        else if(response.error == "Expired or invalid token - please log in again"){
            showToast(response.error,"error");
            yield put(appCreators.clearToken())
        }
        console.log(response.data)
    }
    catch(err){
        console.log(err)
        }
}

export function* getUserApprove(action){
    try{
        const response = yield call(userApprove,action.email,action.date,action?.subscriptions,action?.role)
        if(response.ok){
            showToast(response?.data?.message,'success')
            // const listresponse = yield call(userList)
            //yield put(userManageCreators.successFetchUsers(listresponse.data))
        }
    }
    catch(err){
        showToast(err,"error")
    }
}

export function* getClinicians(){
    try{
        const response = yield call(getCliniciansFunction)
        if(response.status == 200){
            yield put(userManageCreators.setClinicians(response?.data?.records))
        }
    }
    catch(err){
        showToast(err,"error")
    }
}

function* userManagementSaga(){
    yield takeLatest(userManageTypes.REQUEST_FETCH_USERS,getUserData)
    yield takeLatest(userManageTypes.APPROVE_USER_SUCCESS, getUserApprove);
    yield takeLatest(userManageTypes.GET_CLINICIANS, getClinicians);
}

export default userManagementSaga;