import { getClient, setClient } from "../utils/apiUtils"
import ApiConstants from "../api/apiConstants"
import { create } from "apisauce"

let configApi = getClient()

export const postLoginUser = payload => {
  configApi = getClient()
  return configApi.post(ApiConstants.LOGIN, payload)
}

export const forgotPassword = payload => {
  return configApi.post(ApiConstants.FORGOT_PASSWORD, payload)
}

export const resetPassword = payload => {
  configApi = getClient()
  return configApi.post(ApiConstants.CHANGE_PASSWORD, payload)
}
export const createUser = payload => {
  return configApi.post(ApiConstants.CREATEUSER, payload)
}

export const registerPassword = payload => {
  return configApi.post(ApiConstants.REGISTER, payload)
}

export const configDataGet = () => {
  return configApi.get(ApiConstants.CONFIG)
}

export const subscriptionDataGet = () => {
  const configApi = getClient()
  return configApi.get(ApiConstants.SUBSCRIPTION)
}

export const registerForm = payload => {
  const configApi = getClient()
  return configApi.put(ApiConstants.UPDATE_USER, payload)
}

export const registerUser = payload => {
  return configApi.post(ApiConstants.CHANGE_PASSWORD, payload)
}

export const getDashboardInfo = () => {
  const configApi = getClient()
  return configApi.get(ApiConstants.DASHBOARDINFO)
}
export const getrefDashboardInfo = selectedOrg => {
  const configApi = getClient()
  return configApi.get(ApiConstants.ANEDASHBOARDINFO + "?org=" + selectedOrg)
}

export const getrefDashboardChartInfo = (time, selectedOrg) => {
  const configApi = getClient()
  return configApi.get(
    ApiConstants.ANEDASHBOARDINFO + "?duration=" + time + "&org=" + selectedOrg
  )
}

export const uploadImage = async (uploadUrl, imageBody, type) => {
  let options
  options = {
    headers: {
      "Content-Type": type,
    },
    method: "PUT",
    body: imageBody,
  }
  return fetch(uploadUrl, options)
}

export function getMyReferralsService({
  selectedType,
  Status,
  page = 0,
  limit = 100,
  userRole = "BCH_CLINICIAN",
  durtype,
  search = "",
  timeFrame = "",
  org,
  orderColumn = "",
  orderType = "",
  assignedRev = "ALL",
}) {
  const type = durtype
  const Decision = selectedType
  const configApi = getClient()

  return configApi.get(ApiConstants.DASHBOARDDETAILS, {
    Decision,
    page,
    limit,
    search,
    timeFrame,
    Status,
    type,
    org,
    orderColumn,
    orderType,
    assignedRev,
  })
}

export function extendToken() {
  configApi = getClient()
  return configApi.get(ApiConstants.EXTEND_TOKEN)
}

export const userList = (
  page = 1,
  limit = 10,
  status = null,
  search = null,
  role = null
) => {
  const configApi = getClient()
  return configApi.get(
    ApiConstants.GET_UNAUTHORISED_LIST +
      `?page=${page}&limit=${limit}&status=${status}&search=${search}&role=${role}`
  )
}

export const userApprove = (email, date, subscription = null, role = null) => {
  const configApi = getClient()
  return configApi.post(ApiConstants.AUTHORISE_USER, {
    email: email,
    expiryDate: date,
    subscription: subscription,
    roleID: role,
  })
}

export const verifyOTP = (email, password, code) => {
  const configApi = getClient()
  return configApi.post(ApiConstants.VERIFY_USER_2FA, { email, password, code })
}

export const requestQR = token => {
  if (token) setClient(token)
  const configApi = getClient()
  return configApi.get(ApiConstants.REQUEST_QR_CODE_WITH_AUTH)
}

export const setupMFA = code => {
  const configApi = getClient()
  return configApi.post(ApiConstants.SETUP_2FA, { code })
}

export const destroyToken = () => {
  const configApi = getClient()
  return configApi.post(ApiConstants.DESTROY_TOKEN)
}

export const requestBackupCode = email => {
  const configApi = getClient()
  return configApi.post(ApiConstants.REQUEST_2FA_BACKUP, {
    email: email,
  })
}

export const getTemplates = () => {
  const configApi = getClient()
  return configApi.get(ApiConstants.GET_TEMPLATES)
}

export const getFaqlists = () => {
  const configApi = getClient()
  return configApi.get(ApiConstants.FAQ_LIST)
}

export const orgSearchval = searchval => {
  configApi = getClient()
  return configApi.get(ApiConstants.ORG_SEARCH_VAL + searchval)
}

export function setOnCall(payload) {
  const configApi = getClient()
  return configApi.put(`${ApiConstants.SET_ON_CALL}`, payload)
}

export function getOnCall() {
  const configApi = getClient()
  return configApi.get(`${ApiConstants.GET_ON_CALL}`)
}

export const recordList = (
  page = 1,
  limit = 10,
  search = null,
  status = null
) => {
  const configApi = getClient()
  return configApi.get(
    ApiConstants.GET_RECORDS +
      `?page=${page}&limit=${limit}&search=${search}&status=${status}`
  )
}

export const recordPDF = (caseID, htmlString, actions) => {
  const configApi = getClient()
  return configApi.post(
    ApiConstants.RECORD_PDF,
    {
      caseID,
      htmlString,
      actions,
    },
    { responseType: "blob" }
  )
}

export const updateRecord = payload => {
  const configApi = getClient()
  return configApi.put(ApiConstants.UPDATE_RECORD, payload)
}

export const sendRecord = payload => {
  const configApi = getClient()
  return configApi.post(ApiConstants.SEND_RECORD, payload)
}

export const verifyUser = payload => {
  const configApi = getClient()
  return configApi.post(ApiConstants.VERIFY_PATIENT, payload)
}

export const verifyInterpreterDetails = payload => {
  const configApi = getClient()
  return configApi.post(ApiConstants.VERIFY_INTERPRETER, payload)
}

export const sendEmailInvite = payload => {
  const configApi = getClient()
  return configApi.put(ApiConstants.SEND_INVITE, payload)
}

export const sendReopenReq = payload => {
  const configApi = getClient()
  return configApi.put(ApiConstants.REOPEN_RECORD, payload)
}

export const getBread = e => {
  const configApi = getClient()
  return configApi.get(ApiConstants.GET_PDF_URL, e)
}

export const importConsent = payload => {
  const formData = new FormData()
  formData.append("file", payload.file)
  const configApi = getClient()
  return configApi.post(ApiConstants.IMPORT_CONSENTS, formData)
}

export const getConsents = payload => {
  const configApi = getClient()
  return configApi.get(
    ApiConstants.GET_CONSENTS + "?myConsents=" + payload.payload
  )
}

export const sendConsentToSign = payload => {
  const configApi = getClient()
  return configApi.post(ApiConstants.SEND_CONSENT_TO_SIGN , payload.payload)
}

export const uploadBitpaperUrl = payload => {
  const configApi = getClient()
  return configApi.post(ApiConstants.BITPAPER_UPLOAD , payload.payload)
}

export const getCliniciansFunction = payload => {
  const configApi = getClient()
  return configApi.get(ApiConstants.GET_CLINICIANS)
}

export const importPatientsFun = payload => {
  const configApi = getClient()
  return configApi.post(ApiConstants.IMPORT_PATIENTS, payload)
}

export const bulkAddPatientFun = payload => {
  const configApi = getClient()
  return configApi.post(ApiConstants.BULK_ADD_PATIENTS, {patients: payload})
}

export const getListedPatientsFun = payload => {
  const configApi = getClient()
  return configApi.get(ApiConstants.GET_LISTED_PATIENTS+ "?startDate=" + payload?.startDate + "&endDate=" + payload?.endDate + "&clinician=" + payload?.clinician + "&listType=" + payload?.listType)
}

export const verifyNewUser = payload => {
  const configApi = getClient()
  return configApi.post(ApiConstants.VERIFY_USER, payload)
}

export const getDetailsByToken = payload => {
  const configApi = getClient()
  return configApi.get(ApiConstants.GET_DETAILS_BY_TOKEN + "/" + payload)
}

export const downloadConsent = payload => {
  const configApi = getClient()
  return configApi.get(
    ApiConstants.DOWNLOAD_CONSENT + "?request_id=" + payload.payload
  )
}

export const finalImport = payload => {
  console.log(payload)
  const configApi = getClient()
  return configApi.post(ApiConstants.FINAL_IMPORT, payload)
}

export const deleteOldRecord = payload => {
  console.log(payload)
  const configApi = getClient()
  return configApi.put(ApiConstants.DELETE_OLD, payload)
}

export const createNewProcedure = payload => {
  const configApi = getClient()
  return configApi.post(ApiConstants.CREATE_PROCEDURE, payload)
}

export const updateProcedure = payload => {
  debugger
  const configApi = getClient()
  return configApi.put(ApiConstants.UPDATE_PROCEDURE, payload)
}

export const updateProcedureStatus = payload => {
  const configApi = getClient()
  return configApi.post(ApiConstants.CHANGE_PROCEDURE_STATUS, payload)
}

export const updatePatientStatus = payload => {
  const configApi = getClient()
  return configApi.post(ApiConstants.CHANGE_PATIENT_STATUS, payload)
}

export const getProcedures = (
  search = null,
  page = 1,
  limit = 10,
  status = null
) => {
  const configApi = getClient()
  return configApi.get(
    ApiConstants.SEARCH_PROCEDURES +
      `?page=${page}&limit=${limit}&search=${search}&status=${status}`
  )
}

export const getProcedureDetails = payload => {
  const configApi = getClient()
  return configApi.get(ApiConstants.PROCEDURE_DETAILS + "?id=" + payload)
}

export const getProceduresList = payload => {
  const configApi = getClient()
  return configApi.get(ApiConstants.SEARCH_PROCEDURES, payload)
}

export const addProcedureSection = payload => {
  const configApi = getClient()
  return configApi.get(
    ApiConstants.ADD_TO_SECTION + "?id=" + payload.id + "&section=" + payload.section
  )
}

export const createNewPatient = payload => {
  const configApi = getClient()
  return configApi.post(ApiConstants.CREATE_PATIENT, payload.payload)
}

export const updatePatient = payload => {
  const configApi = getClient()
  return configApi.put(ApiConstants.UPDATE_PATIENT, payload)
}

export const searchPatientFun = payload => {
  const configApi = getClient()
  return configApi.get(`${ApiConstants.SEARCH_PATIENT}?search=${payload}`)
}

export const createNewConsent = payload => {
  const configApi = getClient()
  return configApi.post(ApiConstants.CREATE_CONSENT, payload)
}
export const createTemplates = payload => {
  const configApi = getClient()
  return configApi.post(ApiConstants.CREATE_TEMPLATE, payload)
}

export const fetchTemplates = payload => {
  const configApi = getClient()
  return configApi.get(ApiConstants.GET_TEMPLATES, payload)
}

export const updateConsents = payload => {
  const configApi = getClient()
  return configApi.put(ApiConstants.UPDATE_CONSENT, payload)
}

export const sendForSignature = payload => {
  const configApi = getClient()
  return configApi.post(ApiConstants.SEND_FOR_SIGNATURE, payload)
}

export const getRoles = (starteDate, endDate, role, updatedStartDate, updatedEndDate) => {
  const configApi = getClient()
  return configApi.get(
    `${ApiConstants.GET_ROLES}?startDate=${starteDate}&endDate=${endDate}&role=${role}&updatedStartDate=${updatedStartDate}&updatedEndDate=${updatedEndDate}`
  )
}

export const deleteRoleMethod = id => {
  const configApi = getClient()
  return configApi.get(ApiConstants.DELETE_ROLE + `?id=${id}`)
}

export const createRole = payload => {
  const configApi = getClient()
  return configApi.post(ApiConstants.CREATE_ROLE, payload)
}

export const updateRole = payload => {
  const configApi = getClient()
  return configApi.post(ApiConstants.UPDATE_ROLE, payload)
}
