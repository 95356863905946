import { createActions } from "reduxsauce"

export const { Types: procedureTypes, Creators: procedureCreators } =
  createActions({
    requestFetchProcedures: ["page", "limit", "search"],
    successFetchProcedures: ["procedures"],
    failureFetchProcedures: ["error"],
    createProcedure: [
      "procedureName",
      "procedureDescription",
      "procedureAcronym",
      "benefits",
      "risks",
      "alternatives",
      "videoLinks",
      "Leaflets",
      "imageRefs",
    ],
    updateProcedure: [
      "procedureID",
      "procedureName",
      "procedureDescription",
      "procedureAcronym",
      "benefits",
      "risks",
      "alternatives",
      "videoLinks",
      "Leaflets",
      "imageRefs",
    ],
    changeProcedureStatus: ["payload"],
    proceduresList: [],
    setFormToggle: ["payload"],
    getProcedureDetails: ["payload"],
    procedureDetailsResponse: ["payload"],
    setLoadingAddProcedure: ["payload"],
    addToSection: ['payload']
  })

const InitialState = {
  procedure: [],
  loading: null,
  error: null,
  newProcedure: [],
  proceduresList: [],
  requestFetchProcedures: [],
  toggleForm: null,
  details: {},
  loadingAddProcedure: true,
}

const procedureReducer = (state = InitialState, action) => {
  console.log(action)
  switch (action.type) {
    case procedureTypes.CREATE_PROCEDURE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case procedureTypes.CREATE_PROCEDURE_RESPONSE:
      return {
        ...state,
        loading: false,
        error: null,
        newProcedure: action,
      }
    case procedureTypes.CREATE_PROCEDURE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    case procedureTypes.SUCCESS_FETCH_PROCEDURES:
      return {
        ...state,
        proceduresList: action.data.procedures,
        error: action.error,
      }
    case procedureTypes.SET_FORM_TOGGLE:
      return {
        ...state,
        toggleForm: action.payload,
      }
      
    case procedureTypes.GET_PROCEDURE_DETAILS:
    case procedureTypes.CHANGE_PROCEDURE_STATUS:
    case procedureTypes.ADD_TO_SECTION:
      return {
        ...state,
      }

    case procedureTypes.PROCEDURE_DETAILS_RESPONSE:
      return {
        ...state,
        details: action.payload,
      }

    case procedureTypes.SET_LOADING_ADD_PROCEDURE: {
      return {
        ...state,
        loadingAddProcedure: action.payload,
      }
    }
    default:
      return state
  }
}

export default procedureReducer
