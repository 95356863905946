import { call, put, takeLatest } from "redux-saga/effects"
import { procedureTypes, procedureCreators } from "./reducer"
import { appCreators } from "store/app/appReducer"
import { showToast } from "utils/toastnotify"
import {
  addProcedureSection,
  createNewProcedure,
  getProcedureDetails,
  getProceduresList,
  updateProcedure,
  updateProcedureStatus,
} from "servicies/UserServicies"
import { consentCreators } from "store/consent/reducer"

export function* createProcedures(action) {
  debugger
  try {
    yield put(procedureCreators.setLoadingAddProcedure(true))
    const response = yield call(createNewProcedure, action.payload)
    if (response.ok) {
      yield put(procedureCreators.setLoadingAddProcedure(false))
      if (response.data.message) {
        showToast(response.data.message, "success")
        yield put(procedureCreators.setFormToggle(false))
        yield put(consentCreators.searchProcedures(null))
      }
      // yield put(procedureCreators.verifyPatientResponse(response.data.url))
    } else {
      yield put(procedureCreators.setLoadingAddProcedure(false))
      showToast(response?.data?.message || "Something went wrong", "error")
      // yield put(procedureCreators.verifyPatientResponse(response.data.message))
    }
  } catch (error) {
    yield put(procedureCreators.setLoadingAddProcedure(false))
    console.log(error)
    showToast("Something went wrong", "error")
  }
}

export function* requestFetchProcedures(action) {
  const response = yield call(
    getProceduresList,
    action.page,
    action.limit,
    action.search,
    action.satatus
  )
  console.log(response, "response")
  try {
    debugger
    if (response.ok) {
      yield put(procedureTypes.requestFetchProcedures(response))
      console.log("success procedures")
    } else if (
      response.error == "Expired or invalid token - please log in again"
    ) {
      showToast(response.error, "error")
      yield put(appCreators.clearToken())
    } else {
      // yield put(fetchProcedureFailure(response.data))
    }
  } catch (error) {
    console.log(error)
    showToast("Something went wrong", "error")
  }
}

export function* updatProcedure(action) {
  debugger
  try {
    yield put(procedureCreators.setLoadingAddProcedure(true))
    const response = yield call(updateProcedure, action.payload)
    if (response.ok) {
      yield put(procedureCreators.setLoadingAddProcedure(false))
      if (response.data.message) {
        showToast(response.data.message, "success")
        yield put(procedureCreators.setFormToggle(false))
        yield put(consentCreators.searchProcedures(null))
      }
    } else {
      yield put(procedureCreators.setLoadingAddProcedure(false))
      showToast(response?.data?.message || "Something went wrong", "error")
    }
  } catch (error) {
    yield put(procedureCreators.setLoadingAddProcedure(false))
    console.log(error)
    showToast("Something went wrong", "error")
  }
}

export function* updatProcedureStatus(action) {
  debugger
  try {
    const response = yield call(updateProcedureStatus, action.payload)
    if (response.ok) {
      if (response.data.message) {
        showToast(response.data.message, "success")
        yield put(consentCreators.searchProcedures(null))
      }
    } else {
      showToast(response?.data?.message || "Something went wrong", "error")
    }
  } catch (error) {
    console.log(error)
    showToast("Something went wrong", "error")
  }
}

export function* getProcedureDetail(action) {
  try {
    const response = yield call(getProcedureDetails, action.payload)
    if (response.ok) {
      yield put(procedureCreators.procedureDetailsResponse(response.data))
    } else {
      showToast(response?.data?.message || "Something went wrong", "error")
    }
  } catch (error) {
    console.log(error)
    showToast("Something went wrong", "error")
  }
}

export function* addToSectionSaga(action) {
  try {
    const response = yield call(addProcedureSection, action.payload)
    if (response.ok) {
      yield put(procedureCreators.procedureDetailsResponse(response.data))
      if (response?.data?.message) {
        yield put(appCreators.updatePersonalProcedures(response?.data?.data || []))
        showToast(response.data.message, "success")
      }
    } else {
      if (response?.data?.message) {
        showToast(response.data.message, "error")
      } else {
        showToast("Something went wrong", "error")
      }
    }
  } catch (error) {
    console.log(error)
    showToast("Something went wrong", "error")
  }
}

function* procedureSaga() {
  debugger
  yield takeLatest(
    procedureTypes.REQUEST_FETCH_PROCEDURES,
    requestFetchProcedures
  )
  yield takeLatest(procedureTypes.CREATE_PROCEDURE, createProcedures)
  yield takeLatest(procedureTypes.UPDATE_PROCEDURE, updatProcedure)
  yield takeLatest(procedureTypes.CHANGE_PROCEDURE_STATUS, updatProcedureStatus)
  yield takeLatest(procedureTypes.GET_PROCEDURE_DETAILS, getProcedureDetail)
  yield takeLatest(procedureTypes.ADD_TO_SECTION, addToSectionSaga)
}

export default procedureSaga
