import { createActions } from "reduxsauce"

export const { Types: interpreterTypes, Creators: interpreterCreators } =
  createActions({
    setStep: ["step"],
    verifyInterpreterRequest: ["id", "interpreterDetails"],
    verifyInterpreterResponse: ["url"],
    verifyInterpreterFailure: ["error"],
  })

const InitialState = {
  step: 1,
  url: null,
  loading: null,
  error: null,
}

const interpreterReducer = (state = InitialState, action) => {
  switch (action.type) {
    case interpreterTypes.SET_STEP:
      return {
        ...state,
        step: action.step,
      }
    case interpreterTypes.VERIFY_INTERPRETER_REQUEST:
      return {
        ...state,
        loading: false,
        error: null,
      }
    case interpreterTypes.VERIFY_INTERPRETER_RESPONSE:
      return {
        ...state,
        loading: false,
        error: null,
        url: action.url,
      }
    case interpreterTypes.VERIFY_INTERPRETER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        url: null,
      }
    default:
      return state
  }
}

export default interpreterReducer
