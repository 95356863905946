import { createActions } from "reduxsauce"

export const { Types: consentTypes, Creators: consentCreators } = createActions(
  {
    getConsents: ["payload"],
    setConsentResponse: ["payload"],
    downloadConsent: ["payload"],
    setPatient: ["obj"],
    setShowParent: ["bool"],
    setParent: ["obj"],
    setShowInterpreter: ["bool"],
    setInterpreter: ["obj"],
    setNewConsent: ["obj"],
    updateConsent: ["obj"],
    setPatientId: ["id"],
    setStatus: ["status"],
    // Comments
    setInterpreterComment: ["comment"],
    setPatientComment: ["comment"],
    setChildAgrementComment: ["comment"],
    // createProcedureRequest: [],
    requestTemplates: [],
    successTemplates: ["templates"],
    failureTemplates: ["err"],
    setActiveTemplate: ["data"],
    setContent: ["data"],
    createConsent: ["obj"],

    serializeSlateObject: ["node"],
    serializeSlateObjectSuccess: ["html"],
    serializeSlateObjectFailure: ["error"],
    setLastPageOfPDF: ["number"],
    uploadConsentRecord: [
      "node",
      "caseID",
      "patient",
      "clinician",
      "interpreter",
    ],
    uploadConsentRecordSuccess: ["response"],
    uploadConsentRecordFailure: ["error"],

    updateRecord: ["payload"],
    updateRecordSuccess: ["response"],
    updateRecordFailure: ["error"],

    sendRecord: ["payload"],
    sendRecordSuccess: ["response"],
    sendRecordFailure: ["error"],

    setId: ["id"],
    setURL: ["url"],
    setCompleted: ["bool"],
    setTimelineData: ["data"],

    setEditor: ["editor"],
    resetAll: [],

    sendInvite: ["id", "invites"],
    sendInviteResponse: ["response"],

    reopenCase: ["id"],
    sendReopenResponse: ["response"],

    getBread: ["id"],
    getBreadResponse: ["response"],
    setVisibilityType: ["payload"],
    setLoadingConsult: ["payload"],
    searchProcedures: ["search", "page", "limit", "status"],
    searchProceduresResponse: ["response"],
    searchProceduresLoading: ["bool"],
    setProcedures: ["obj"],
    setInterpreter: ["obj"],
    setShowInterpreter: ["bool"],
    setParentBlock: ["bool"],
    setChildAgrement: ["bool"],
    setActiveTab: ["index"],
    resetConsent: ["bool"],
    setBitpaperURL: ["obj"],
    uploadBitpaperUrl: ['payload'],
    setConsentDetails: ["obj"],
    setBitpaperPdfURL: ["url"],
    setTimeline: ['payload'],
    setPresignedDocumentUrl: ["url"],
    sendForSignature: ["obj"],
    setOldProcedures: ["oldprocedures"],
    setLoadingDashboardConsents: ["bool"],
    sendConsentToSign: ["payload"],
    setSendConsentToSignLoading: ["bool"],
    verifyUser: ["payload"],
    setVerifyUserLoading: ["bool"],
    setIsUserVerified: ['bool'],
    getDetailsByToken: ["payload"],
    setSignConsentDetails: ["payload"],
    setSignConsentDetailsResponse: ['payload'],
    setSignConsentDetailsLoading: ["bool"],
  }
)

const InitialState = {
  patient: {},
  showParent: false,
  parent: {},
  showInterpreter: false,
  interpreter: {},
  templates: [],
  templatesLoading: false,
  activeTemplate: {},
  content: [],
  html: "",
  consentID: null,
  previousConsentId: null,
  editor: null,
  pdfURL: null,
  bitpaperURL: null,
  completed: false,
  timelineData: [],
  patientID: null,
  status: "",
  searchProcedures: [],
  searchProceduresLoading: false,
  consent: {},
  interpreter: {},
  procedures: [],
  showInterpreter: false,
  parentBlock: false,
  childAgrement: false,
  activeTab: 1,
  interpreterComment: "",
  childAgreementComment: "",
  patientComment: "",
  status: [],
  bitpaperURL: {},
  consentDetails: {},
  consents: [],
  visibilityType: "",
  loadingConsult: false,
  oldprocedures: [],
  loadingDashboardConsents: false,
  sendConsentToSignLoading: false,
  verifyUserLoading: false,
  isUserVerified: false,
  signConsentDetails: {},
  signConsentDetailsResponse: {},
  signConsentDetailsLoading: false,
}

const consentReducer = (state = InitialState, action) => {
  switch (action.type) {
    case consentTypes.RESET_CONSENT:
      return {
        ...state,
        showParent: false,
        parent: {},
        showInterpreter: false,
        interpreter: {},
        consentID: null,
        consent: {},
        interpreter: {},
        procedures: [],
        showInterpreter: false,
        parentBlock: false,
        childAgrement: false,
        activeTab: 1,
        interpreterComment: "",
        childAgreementComment: "",
        patientComment: "",
        status: [],
        bitpaperURL: {},
        consentDetails: {},
        previousConsentId: null,
        signature: {},
        oldprocedures: [], //after reconsent it stores the procedures of old consnet.
        // lastPageOfPDF,
      }
    case consentTypes.SET_LOADING_DASHBOARD_CONSENTS:
      return {
        ...state,
        loadingDashboardConsents: action.bool,
      }
    case consentTypes.SET_PATIENT:
      return {
        ...state,
        patient: action.obj,
      }
    case consentTypes.SET_OLD_PROCEDURES:
      return {
        ...state,
        oldprocedures: action.oldprocedures,
      }
    case consentTypes.SET_LAST_PAGE_OF_PDF:
      return {
        ...state,
        lastPageOfPDF: action.number,
      }
    case consentTypes.SET_SHOW_PARENT:
      return {
        ...state,
        showParent: action.bool,
      }
    case consentTypes.SET_PARENT:
      return {
        ...state,
        parent: action.obj,
      }
    case consentTypes.SET_SHOW_INTERPRETER:
      return {
        ...state,
        showInterpreter: action.bool,
      }
    case consentTypes.SET_INTERPRETER:
      return {
        ...state,
        interpreter: action.obj,
      }
    case consentTypes.REQUEST_TEMPLATES:
      return {
        ...state,
        templatesLoading: true,
      }
    case consentTypes.GET_CONSENTS:
    case consentTypes.DOWNLOAD_CONSENT:
    case consentTypes.FAILURE_TEMPLATES:
    case consentTypes.SERIALIZE_SLATE_OBJECT:
    case consentTypes.SERIALIZE_SLATE_OBJECT_FAILURE:
    case consentTypes.UPLOAD_CONSENT_RECORD:
    case consentTypes.UPLOAD_CONSENT_RECORD_FAILURE:
    case consentTypes.UPDATE_RECORD:
    case consentTypes.UPDATE_RECORD_SUCCESS:
    case consentTypes.UPDATE_RECORD_FAILURE:
    case consentTypes.SEND_RECORD:
    case consentTypes.SEND_RECORD_SUCCESS:
    case consentTypes.SEND_RECORD_FAILURE:
    case consentTypes.SEND_INVITE:
    case consentTypes.SEND_INVITE_RESPONSE:
    case consentTypes.GET_BREAD:
    case consentTypes.SEARCH_PROCEDURES:
    case consentTypes.SEND_CONSENT_TO_SIGN:
    case consentTypes.VERIFY_USER:
    case consentTypes.GET_DETAILS_BY_TOKEN:
    case consentTypes.UPLOAD_BITPAPER_URL: 
      return { ...state }
    
    case consentTypes.SET_IS_USER_VERIFIED: {
      return {...state, isUserVerified: action.bool}
    }
    case consentTypes.SET_SIGN_CONSENT_DETAILS: {
      return { ...state, signConsentDetails: action.payload }
    }
    case consentTypes.SET_SIGN_CONSENT_DETAILS_RESPONSE: {
      return { ...state, signConsentDetailsResponse: action.payload, signConsentDetails: action.payload?.consentID }
    }
    case consentTypes.SET_SIGN_CONSENT_DETAILS_LOADING: {
      return { ...state, signConsentDetailsLoading: action.bool }
    }
    case consentTypes.SET_SEND_CONSENT_TO_SIGN_LOADING:
      return {
        ...state,
        sendConsentToSignLoading: action.bool,
      }

    case consentTypes.SET_VERIFY_USER_LOADING:
      return {
        ...state,
        verifyUserLoading: action.bool,
      }

    case consentTypes.SET_VISIBILITY_TYPE:
      return {
        ...state,
        visibilityType: action.payload,
      }

    case consentTypes.SET_CONSENT_RESPONSE:
      return {
        ...state,
        consents: action.payload,
      }
    case consentTypes.SET_LOADING_CONSULT: {
      return {
        ...state,
        loadingConsult: action.payload,
      }
    }
    case consentTypes.SUCCESS_TEMPLATES:
      return {
        ...state,
        templates: action.templates,
      }
    case consentTypes.SET_ACTIVE_TEMPLATE:
      if (action.data == undefined) {
        return {
          ...state,
          activeTemplate: {},
        }
      }
      return {
        ...state,
        activeTemplate: action.data,
        content: action.data.data,
      }
    case consentTypes.SET_CONTENT:
      return {
        ...state,
        content: action.data,
      }
    case consentTypes.SERIALIZE_SLATE_OBJECT_SUCCESS:
      return {
        ...state,
        html: action.html,
      }
    case consentTypes.UPLOAD_CONSENT_RECORD_SUCCESS:
      return {
        ...state,
        pdfURL: action.response,
      }
    case consentTypes.SET_ID:
      return {
        ...state,
        consentID: action.id,
      }
    case consentTypes.SET_PREVIOUS_CONSENT_ID:
      return {
        ...state,
        previousConsentId: action.id,
      }

    case consentTypes.SET_PATIENT_ID:
      return {
        ...state,
        patientID: action.id,
      }
    case consentTypes.SET_URL:
      return {
        ...state,
        bitpaperURL: action.url,
      }

    case consentTypes.SET_EDITOR:
      return {
        ...state,
        editor: action.editor,
      }
    case consentTypes.SET_COMPLETED:
      return {
        ...state,
        completed: action.bool,
      }
    case consentTypes.SET_TIMELINE_DATA:
      return {
        ...state,
        timelineData: action.data,
      }
    case consentTypes.REOPEN_CASE:
      return {
        ...state,
        completed: false,
      }

    case consentTypes.SEARCH_PROCEDURES_RESPONSE:
      return {
        ...state,
        searchProcedures: action.response?.data,
      }
    case consentTypes.SEARCH_PROCEDURES_LOADING:
      return {
        ...state,
        searchProceduresLoading: action.bool,
      }

    case consentTypes.RESET_ALL:
      return InitialState

    case consentTypes.SET_NEW_CONSENT:
      return {
        ...state,
        consent: action.obj,
      }
    case consentTypes.SET_PROCEDURES:
      return {
        ...state,
        procedures: action.obj,
      }
    case consentTypes.SET_INTERPRETER:
      return {
        ...state,
        interpreter: action.obj,
      }
    case consentTypes.SET_SHOW_INTERPRETER:
      return {
        ...state,
        showInterpreter: action.bool,
      }
    case consentTypes.SET_C:
      return {
        ...state,
        showInterpreter: action.bool,
      }
    case consentTypes.SET_PARENT_BLOCK:
      return {
        ...state,
        parentBlock: action.bool,
      }
    case consentTypes.SET_CHILD_AGREMENT:
      return {
        ...state,
        childAgrement: action.bool,
      }
    case consentTypes.SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.index,
      }
    case consentTypes.SET_INTERPRETER_COMMENT:
      return {
        ...state,
        interpreterComment: action.comment,
      }
    case consentTypes.SET_PATIENT_COMMENT:
      return {
        ...state,
        patientComment: action.comment,
      }

    case consentTypes.SET_CHILD_AGREMENT_COMMENT:
      return {
        ...state,
        childAgreementComment: action.comment,
      }
    case consentTypes.SET_STATUS:
      return {
        ...state,
        status: action.status,
      }
    case consentTypes.SET_BITPAPER_URL:
      return {
        ...state,
        consentDetails: { ...state.consentDetails, bitpaper: action.obj },
      }
    case consentTypes.SET_TIMELINE:
      return {
        ...state,
        consentDetails: { ...state.consentDetails, Timeline: action.payload },
      }
    case consentTypes.SET_CONSENT_DETAILS:
      return {
        ...state,
        consentDetails: action.obj,
      }
    case consentTypes.SET_BITPAPER_PDF_URL:
      return {
        ...state,
        consentDetails: { ...state.consentDetails, bitpaperPdfUrl: action.url },
      }
    case consentTypes.SET_PRESIGNED_DOCUMENT_URL:
      return {
        ...state,
        consentDetails: {
          ...state.consentDetails,
          presignedDocumentUrl: action.url,
        },
      }
    default:
      return state
  }
}

export default consentReducer
