import PropTypes from "prop-types"
import React, { Suspense, lazy, useEffect } from "react"

import { Switch, BrowserRouter as Router } from "react-router-dom"
import { connect, useSelector } from "react-redux"

import { Redirect } from "react-router-dom"

import { selectToken } from "store/app/appSelector"

// // Import Routes all
import { authProtectedRoutes, publicRoutes } from "./routes"

// Import all middleware
import Authmiddleware from "./routes/route"

// layouts Format

const VerticalLayout = lazy(() => import("./components/VerticalLayout/"))
const HorizontalLayout = lazy(() => import("./components/HorizontalLayout/"))
const NonAuthLayout = lazy(() => import("./components/NonAuthLayout"))

import { browserName } from "react-device-detect"

// Import scss
import "./assets/scss/theme.scss"

// Import Firebase Configuration file
// import { initFirebaseBackend } from "./helpers/firebase_helper"

// import fakeBackend from "./helpers/AuthType/fakeBackend"
import { setClient, setStore } from "utils/apiUtils"
import useNetworkStatus from "hooks/useNetworkStatus"
import toastr from "toastr"

const PagesMaintenance = lazy(() =>
  import("pages/browserSupport/pageMaintanence")
)
const UserManagement = lazy(() => import("pages/UserManagement"))
// Activating fake backend
// fakeBackend()

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_APIKEY,
//   authDomain: process.env.REACT_APP_AUTHDOMAIN,
//   databaseURL: process.env.REACT_APP_DATABASEURL,
//   projectId: process.env.REACT_APP_PROJECTID,
//   storageBucket: process.env.REACT_APP_STORAGEBUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
//   appId: process.env.REACT_APP_APPID,
//   measurementId: process.env.REACT_APP_MEASUREMENTID,
// }

// init firebase backend
// initFirebaseBackend(firebaseConfig)

const App = props => {
  // const dispatch = useDispatch()
  function getLayout() {
    let layoutCls = VerticalLayout
    switch (props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout
        break
      default:
        layoutCls = VerticalLayout
        break
    }
    return layoutCls
  }

  const Layout = getLayout()
  const { isOnline, isSlowConnection } = useNetworkStatus()
  const { tokenExpiry, token, unsupportedBrowser, appRole } = useSelector(
    state => ({
      tokenExpiry: new Date(state.appReducer?.tokenExpiryDate),
      token: state.appReducer.token,
      unsupportedBrowser: state.appReducer.unsupportedBrowser,
      appRole: state.appReducer?.userDetails?.appRole,
    })
  )

  if (
    appRole == "BCH_CLINICIAN" &&
    !authProtectedRoutes.find(item => item.path == "/User-Management")
  ) {
    authProtectedRoutes.push({
      path: "/User-Management",
      component: UserManagement,
      exact: true,
    })
  }

  useEffect(() => {
    if(!isSlowConnection) {
      toastr.clear()
    }
    if(!isOnline) {
      const position = "toast-top-right"
      const showEasing = "swing"
      const hideEasing = "linear"
      const showMethod = "fadeIn"
      const hideMethod = "fadeOut"
      const showDuration = "300"
      const hideDuration = "1000"

      toastr.options = {
        // id: 1,
        positionClass: position,
        timeOut: 0,
        // extendedTimeOut: extendedTimeOut,
        showEasing: showEasing,
        hideEasing: hideEasing,
        showMethod: showMethod,
        hideMethod: hideMethod,
        showDuration: showDuration,
        hideDuration: hideDuration,
        tapToDismiss: true,
        extendedTimeOut: 0,
        closeButton: true,
      }

      toastr.error("You are offline")
    } else {
      if(!isSlowConnection) {
        toastr.clear()
      }
    }
  }, [isOnline])

  useEffect(() => {
    if(toastr) {
      toastr.clear()
    }
    if(isSlowConnection) {
      const position = "toast-top-right"
      const showEasing = "swing"
      const hideEasing = "linear"
      const showMethod = "fadeIn"
      const hideMethod = "fadeOut"
      const showDuration = "300"
      const hideDuration = "1000"

      toastr.options = {
        // id: 1,
        positionClass: position,
        timeOut: 0,
        // extendedTimeOut: extendedTimeOut,
        showEasing: showEasing,
        hideEasing: hideEasing,
        showMethod: showMethod,
        hideMethod: hideMethod,
        showDuration: showDuration,
        hideDuration: hideDuration,
        tapToDismiss: true,
        extendedTimeOut: 0,
        closeButton: true,
      }

      toastr.info("Your internet connection speed is slow, please be patient")
    } else if(!isOnline) {
      toastr.clear()
    } else {
      toastr.clear()
    }
  }, [isSlowConnection])

  useEffect(() => {
    if (props.app.token) {
      console.log("props.app.token", props.app.token)
      setClient(props.app.token)
      setStore(props.store)
    }
  }, [])

  console.log("unsupportedBrowser==", browserName)
  if (browserName === "IE") {
    return <PagesMaintenance />
  } else {
    return (
      <React.Fragment>
        <Router>
          <Suspense fallback={getFallbackUI()}>
            <Switch>
              {publicRoutes.map((route, idx) => (
                <Authmiddleware
                  path={route.path}
                  layout={route.path == "/consent/:token" ? Layout : NonAuthLayout}
                  component={route.component}
                  key={idx}
                  isAuthProtected={false}
                  unsupportedBrowser={
                    unsupportedBrowser != null && unsupportedBrowser == true
                  }
                  browserName={browserName}
                  exact={route.exact}
                />
              ))}

              {authProtectedRoutes.map((route, idx) => (
                <Authmiddleware
                  path={route.path}
                  layout={Layout}
                  component={route.component}
                  key={idx}
                  isAuthProtected={true}
                  isLoggedIn={token != null && new Date() < tokenExpiry}
                  exact={route.exact}
                />
              ))}
              <Redirect to="/" />
            </Switch>
          </Suspense>
        </Router>
      </React.Fragment>
    )
  }
}

App.propTypes = {
  layout: PropTypes.any,
  token: PropTypes.any,
  app: PropTypes.any,
  store: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    token: selectToken(),
    layout: state.Layout,
    app: state.appReducer,
  }
}

export default connect(mapStateToProps, null)(App)

const getFallbackUI = () => {
  return (
    <div id="preloader">
      <div id="status">
        <div className="spinner-chase">
          <div className="chase-dot" />
          <div className="chase-dot" />
          <div className="chase-dot" />
          <div className="chase-dot" />
          <div className="chase-dot" />
          <div className="chase-dot" />
        </div>
      </div>
    </div>
  )
}