import { call, put, takeLatest } from "redux-saga/effects"
import { templateCreators, templateTypes } from "./reducer"
import { showToast } from "utils/toastnotify"
import { createTemplates, fetchTemplates } from "servicies/UserServicies"

export function* createTemplate(action) {
  debugger
  try {
    const response = yield call(createTemplates, action.obj)
    if (response.ok) {
      if (response.data.message) {
        showToast(response.data.message, "success")
      }
      yield put(templateCreators.createTemplateSuccess(response.data))
    } else {
      showToast(response?.data?.message || "Something went wrong", "error")
      yield put(templateCreators.createTemplateFailuer(response.data))
    }
  } catch (error) {
    console.log(error)
    showToast("Something went wrong", "error")
  }
}

export function* fetchTemplate(action) {
  const response = yield call(
    fetchTemplates,
    action.page,
    action.limit,
    action.search
  )
  try {
    if (response.ok) {
      yild.put(templateCreators.createTemplateSuccess(response.data.message))
      showToast(response.data.message, "success")
    } else if (
      response.error == "Expired or invalid token - please log in again"
    ) {
      showToast(response.error, "error")
      yild.put(templateCreators.createTemplateFailuer(response.data.message))
    } else {
      yild.put(templateCreators.createTemplateFailuer(response.data.message))
      showToast(response.data.message, "error")
    }
  } catch (error) {
    console.log(error)
    showToast("Something went wrong", "error")
  }
}

function* templateSaga() {
  debugger
  yield takeLatest(templateTypes.FETCH_TEMPLATES_REQUEST, fetchTemplate)
  yield takeLatest(templateTypes.CREATE_TEMPLATE_REQUEST, createTemplate)
}

export default templateSaga
