import { createActions } from "reduxsauce"

const initialState = {
  error: null,
  message: null,
  isLoading: null,
  user: null,
  email: null,
  role: null,
  configdata: null,
  subscriptionData: null,
  orgval: null,
}

export const { Types: registerTypes, Creators: registerCreators } =
  createActions({
    requestEmail: ["role", "email", "history"],
    successEmail: ["alertmsg", "email"],
    failEmail: [""],

    requestRegisterPassword: ["email", "code", "password", "history"],
    successRegisterPassword: ["configdata", "user", "subscriptionData"],
    failRegisterPassword: [""],

    requestForm: ["payload", "token", "history"],
    successForm: [""],
    failForm: [""],

    requestUpdateProfile: ["payload", "token", "history"],
    successUpdateProfile: [""],

    requestGetOrg: ["searchval"],
    successGetOrg: ["orgval"],
  })

const account = (state = initialState, action) => {
  switch (action.type) {
    case registerTypes.REQUEST_EMAIL:
      return {
        ...state,
        isLoading: true,
        error: null,
      }

    case registerTypes.SUCCESS_EMAIL:
      return {
        ...state,
        isLoading: false,
        email: action.email,
        message: action.alertmsg,
        error: null,
      }
    case registerTypes.FAIL_EMAIL:
      return {
        ...state,
        isLoading: false,
        error: null,
      }
    case registerTypes.REQUEST_REGISTER_PASSWORD:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case registerTypes.SUCCESS_REGISTER_PASSWORD:
      return {
        ...state,
        isLoading: false,
        error: null,
        configdata: action.configdata,
        user: action.user,
        subscriptionData: action.subscriptionData,
      }
    case registerTypes.FAIL_REGISTER_PASSWORD:
      return {
        ...state,
        isLoading: false,
        error: null,
      }
    case registerTypes.REQUEST_FORM:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case registerTypes.SUCCESS_FORM:
      return {
        ...state,
        isLoading: false,
        error: null,
      }
    case registerTypes.REQUEST_UPDATE_PROFILE:
      return {
        ...state,
      }
    case registerCreators.SUCCESS_UPDATE_PROFILE:
      return {
        ...state,
      }
    case registerTypes.FAIL_FORM:
      return {
        ...state,
        isLoading: false,
        error: null,
      }
    case registerTypes.REQUEST_GET_ORG:
      return {
        ...state,
      }
    case registerTypes.SUCCESS_GET_ORG:
      return {
        ...state,
        orgval: action.orgval,
      }
    default:
      return state
  }
  return state
}

export default account
