import { call, put, takeLatest } from "redux-saga/effects"
import { roleTypes, roleCreators } from "./reducer"
import { appCreators } from "store/app/appReducer"
import { showToast } from "utils/toastnotify"
import {
  createRole,
  deleteRoleMethod,
  getRoles,
  updateRole,
} from "servicies/UserServicies"

export function* createRoleSaga(action) {
  try {
    const response = yield call(createRole, action.payload)
    if (response.ok) {
      yield put(roleCreators.setLoading(false))
      if (response.data.message) {
        showToast(response.data.message, "success")
      }
      // yield put(roleCreators.verifyPatientResponse(response.data.url))
    } else {
      yield put(roleCreators.setLoading(false))
      showToast(response?.data?.message || "Something went wrong", "error")
    }
  } catch (error) {
    yield put(roleCreators.setLoading(false))
    console.log(error)
    showToast("Something went wrong", "error")
  }
}

export function* requestRolesList(data) {
  const response = yield call(
    getRoles,
    data?.startDate,
    data?.endDate,
    data?.role,
    data?.updatedStartDate,
    data?.updatedEndDate
  )
  try {
    debugger
    if (response.ok) {
      yield put(roleCreators.successFetchRoles(response?.data?.data))
    } else if (
      response.error == "Expired or invalid token - please log in again"
    ) {
      showToast(response.error, "error")
      yield put(appCreators.clearToken())
    } else {
    }
  } catch (error) {
    console.log(error)
    showToast("Something went wrong", "error")
  }
}

export function* deleteRole(payload) {
  try {
    const response = yield call(deleteRoleMethod, payload.id)
    if (response.ok) {
      yield put(roleCreators.requestRolesList("","","", "", ""))
      if (response.data.message) {
        showToast(response.data.message, "success")
      }
    } else if (
      response.error == "Expired or invalid token - please log in again"
    ) {
      showToast(response.error, "error")
      yield put(appCreators.clearToken())
    } else {
    }
  } catch (error) {
    console.log(error)
    showToast("Something went wrong", "error")
  }
}

export function* updateRoleSaga(action) {
  try {
    const response = yield call(updateRole, action.payload)
    if (response.ok) {
      yield put(roleCreators.setLoading(false))
      if (response.data.message) {
        showToast(response.data.message, "success")
      }
    } else {
      yield put(roleCreators.setLoading(false))
      showToast(response?.data?.message || "Something went wrong", "error")
    }
  } catch (error) {
    yield put(roleCreators.setLoading(false))
    console.log(error)
    showToast("Something went wrong", "error")
  }
}

function* roleSaga() {
  yield takeLatest(roleTypes.REQUEST_ROLES_LIST, requestRolesList)
  yield takeLatest(roleTypes.CREATE_ROLE, createRoleSaga)
  yield takeLatest(roleTypes.DELETE_ROLE, deleteRole)
  yield takeLatest(roleTypes.UPDATE_ROLE, updateRoleSaga)
}

export default roleSaga
