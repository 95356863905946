import { call, put, takeLatest } from "redux-saga/effects"
import { interpreterCreators, interpreterTypes } from "./reducer"
import { verifyInterpreterDetails } from "servicies/UserServicies"
import { showToast } from "utils/toastnotify"

export function* verifyInterpreter({ id, interpreterDetails }) {
  try {
    const response = yield call(verifyInterpreterDetails, {
      id,
      interpreterDetails,
    })
    if (response.ok) {
      if (response.data.message) {
        showToast(response.data.message, "success")
      }
      yield put(
        interpreterCreators.verifyInterpreterResponse(response.data.url)
      )
      yield put(interpreterCreators.setStep(2))
    } else {
      if (response.data.message) {
        showToast(response.data.message, "error")
      } else {
        showToast("Something went wrong", "error")
      }
      yield put(
        interpreterCreators.verifyInterpreterResponse(response.data.message)
      )
    }
  } catch (error) {
    if (response.data.message) {
      showToast(response.data.message, "error")
    } else {
      showToast("Something went wrong", "error")
    }
    yield put(
      interpreterCreators.verifyInterpreterResponse(response.data.message)
    )
  }
}

function* interpreterSaga() {
  yield takeLatest(
    interpreterTypes.VERIFY_INTERPRETER_REQUEST,
    verifyInterpreter
  )
}

export default interpreterSaga
