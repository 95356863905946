import { createActions } from "reduxsauce"

export const { Types: bchDashboardTypes, Creators: bchDashboardCreators } =
  createActions({
    requestBchDashboardInfo: ["days", "time"],
    successDashboardInfo: ["caseAmounts"],

    requestRefDashboardInfo: ["selectedOrg"],
    successRefDashboardInfo: ["refcaseAmounts"],

    requestChartData: ["time", "selectedOrg"],
    successChartData: ["chartdata"],

    updateOnCallRegistrar: ["token"],
    setUserOrganisation: ["organisation"],
    setApiVersion: ["apiVersion", "configData"],
    extendToken: [],
    successExtendToken: [],
  })

const INIT_STATE = {
  chartsData: {},
  loading: null,
  caseAmounts: {},
  refcaseAmounts: {},
  orgID: null,
  apiVersion: null,
  extendingToken: false,
  configData: {},
}

const Dashboard = (state = INIT_STATE, action) => {
  switch (action.type) {
    case bchDashboardTypes.SET_USER_ORGANISATION:
      return {
        ...state,
        orgID: action.organisation.orgID,
        extendingToken: false,
      }
    case bchDashboardTypes.SET_API_VERSION:
      return {
        ...state,
        apiVersion: action.apiVersion,
        configData: action.configData,
        extendingToken: false,
      }
    case bchDashboardTypes.REQUEST_BCH_DASHBOARD_INFO:
      return {
        ...state,
        loading: true,
        extendingToken: false,
      }
    case bchDashboardTypes.SUCCESS_DASHBOARD_INFO:
      return {
        ...state,
        loading: false,
        caseAmounts: action.caseAmounts,
        extendingToken: false,
      }
    case bchDashboardTypes.REQUEST_REF_DASHBOARD_INFO:
      return {
        ...state,
        loading: false,
        extendingToken: false,
      }
    case bchDashboardTypes.SUCCESS_REF_DASHBOARD_INFO:
      return {
        ...state,
        loading: true,
        refcaseAmounts: action.refcaseAmounts,
        extendingToken: false,
      }
    case bchDashboardTypes.REQUEST_CHART_DATA:
      return {
        ...state,
        loading: false,
        extendingToken: false,
      }
    case bchDashboardTypes.SUCCESS_CHART_DATA:
      return {
        ...state,
        loading: false,
        chartsData: action.chartdata,
        extendingToken: false,
      }
    case bchDashboardTypes.EXTEND_TOKEN:
      return {
        ...state,
        extendingToken: true,
      }
    case bchDashboardTypes.SUCCESS_EXTEND_TOKEN:
      return {
        ...state,
        extendingToken: false,
      }
    case bchDashboardTypes.UPDATE_ON_CALL_REGISTRAR:
      return state

    default:
      return state
  }
}

export default Dashboard
