import { createActions } from "reduxsauce"

export const { Types: recordTypes, Creators: recordCreators } = createActions({
  requestFetchRecords: ["page", "limit", "search", "status"],
  successFetchRecords: ["records"],
  failureFetchRecords: ["error"],
  createProcedure: [
    "procedureName",
    "procedureDescription",
    "procedureAcronym",
    "benefits",
    "risks",
    "alternatives",
    "videoLinks",
  ],
  importConsents: ["file"],
  successImportConsents: ["records"],
  failureImportConsents: ["err"],
  successFinalImports: [],
  finalImportConsents: ["json"],
  setNewRecords: ["obj"],
  successDeletion: [],
  deleteOldConsent: ["data"],
})

const InitialState = {
  records: [],
  loading: null,
  error: null,
  newRecords: [],
}

const recordReducer = (state = InitialState, action) => {
  switch (action.type) {
    case recordTypes.REQUEST_FETCH_RECORDS:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case recordTypes.SUCCESS_FETCH_RECORDS:
      return {
        ...state,
        loading: false,
        error: null,
        records: action.records,
      }
    case recordTypes.FAILURE_FETCH_RECORDS:
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    case recordTypes.IMPORT_CONSENTS:
      return {
        ...state,
        loading: true,
      }
    case recordTypes.SUCCESS_IMPORT_CONSENTS:
      return {
        ...state,
        newRecords: action.records.data.recordData,
        loading: false,
      }
    case recordTypes.FAILURE_IMPORT_CONSENTS:
      return {
        ...state,
        loading: false,
      }

    case recordTypes.SUCCESS_FINAL_IMPORTS:
      return {
        ...state,
      }

    case recordTypes.FINAL_IMPORT_CONSENTS:
      return {
        ...state,
      }

    case recordTypes.SET_NEW_RECORDS:
      return {
        ...state,
        newRecords: action.obj,
      }
    case recordTypes.DELETE_OLD_CONSENT:
      return {
        ...state,
      }
    case recordTypes.SUCCESS_DELETION:
      return {
        ...state,
      }

    case recordTypes.CREATE_PROCEDURE:
      return {
        ...state,
      }
    default:
      return state
  }
}

export default recordReducer
