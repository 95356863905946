import { lazy } from "react"
// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
const Login = lazy(() => import("../pages/Authentication/Login"))
const Logout = lazy(() => import("../pages/Authentication/Logout"))
const Register = lazy(() => import("../pages/Authentication/Register"))
const ForgetPwd = lazy(() => import("../pages/Authentication/ForgetPassword"))
const Dashboard = lazy(() => import("../pages/Dashboard/index"))
const LoginConsenters = lazy(() => import("../pages/Authentication/LoginConsenters"))
const SignConsent = lazy(() => import("../pages/SignConsent"))
// Dashboard
const PatientDetails = lazy(() => import("pages/Dashboard/patientDetails"))
const AboutUs = lazy(() => import("pages/Aboutus/Aboutus.js"))
const Faqs = lazy(() => import("pages/faqPage/faqs"))
const Terms = lazy(() => import("pages/T&C/terms.js"))
const PageMaintanence = lazy(() =>
  import("pages/browserSupport/pageMaintanence.js")
)
const UserManagement = lazy(() => import("pages/UserManagement"))
const Consent = lazy(() => import("pages/Consents"))
const Roles = lazy(() => import("pages/Roles"))
const AddRole = lazy(() => import("pages/Roles/AddRole"))
const Dashboard2 = lazy(() => import("pages/Dashboard Temp"))
const CreateConsent = lazy(() => import("pages/createConsent"))
const ProcedureDashboard = lazy(() => import("pages/Procedure/index.js"))

// const CreateConsent = lazy(import("pages/createConsent"))

const authProtectedRoutes = [
  // //profile
  { path: "/profile", component: UserProfile, exact: true },

  { path: "/about", component: AboutUs, exact: true },
  { path: "/faqs", component: Faqs, exact: true },
  { path: "/terms", component: Terms, exact: true },
  { path: "/User-Management", component: UserManagement, exact: true },
  { path: "/patient", component: Dashboard, exact: true },
  { path: "/patient/detail", component: PatientDetails, exact: true },
  { path: "/create-consent", component: CreateConsent, exact: true },
  { path: "/consent", component: Consent, exact: true },
  { path: "/procedure", component: ProcedureDashboard, exact: true },
  { path: "/roles", component: Roles, exact: true },
  { path: "/add-role", component: AddRole, exact: true },
  { path: "/edit-role", component: AddRole, exact: true },
  { path: "/", exact: true, component: Dashboard2 },

  //   { path: "/create-patient", component: CreateConsent },
  //   { path: "/pat", component: RecordDashboard },
  // { path: "/feedback", component:Feedback},

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  //   { path: "/", exact: true, component: Consents },
]

const publicRoutes = [
  // { path: "/logout", component: Logout, exact: true },
  { path: "/login", component: Login, exact: true },
  { path: "/login/:token", component: LoginConsenters, exact: true },
  { path: "/consent/:token", component: SignConsent, exact: true },
  { path: "/forgot-password", component: ForgetPwd, exact: true },
  { path: "/reset-password", component: ForgetPwd, exact: true },
  { path: "/register-email", component: Register, exact: true },
  { path: "/register-password", component: Register, exact: true },
  { path: "/update-profile", component: Register, exact: true },
  { path: "/termsconditions", component: Register, exact: true },
  { path: "/setup2fa", component: Register, exact: true },
  { path: "/unsupportedbrowser", component: PageMaintanence, exact: true },
  // { path: "/patient", component: Patient },
  //   { path: "/patient/detail", component: patientDetails },
]

export { publicRoutes, authProtectedRoutes }